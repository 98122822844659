import React from "react"
import { Fade } from "react-awesome-reveal"

const PageTitleSimple = ({ title }) => {
  return (
    <Fade triggerOnce>
      <section className="py-6 md:py-12 lg:pb-20">
        <div className="container-fluid">
          <h1 className="text-center hero">{title}</h1>
        </div>
      </section>
    </Fade>
  )
}

export default PageTitleSimple
