import React from "react"
import { graphql } from "gatsby"
// import { withUnpublishedPreview } from 'gatsby-source-prismic'
import IndexPage from './index'
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import QuickLinks from "../components/QuickLinks"
import PageTitleSimple from "../components/PageTitleSimple"
import Button from "../components/Button"

const NotFoundPage = ({ data }) => {

  if (!data) return null

  const { type } = data.error.edges[0].node
  const document = data.error.edges[0].node.data

  return (
    <Layout pageType={type}>
      <Seo title={document.title.text} />
      <PageTitleSimple title={document.title.text} />
      <section className="py-12 border-t md:pt-12 md:pb-20 border-grey-10">
        <div className="mx-auto lg:max-w-screen-lg">
          <div className="text-center container-fluid">
            <h4 className="mb-10 md:mb-12">{document.copy.text}</h4>
            <Button
              url="/"
              target="_self"
              copy={document.button_copy}
              type="primary"
            />
          </div>
        </div>
      </section>
      <QuickLinks slice={document.body[0]} />
    </Layout>
  )
}

export const query = graphql`
  {
    error: allPrismic404 {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            copy {
              text
            }
            button_copy
            body {
              ... on Prismic404DataBodyQuickLinks {
                slice_type
                items {
                  link_title
                  link {
                    slug
                    url
                    uid
                  }
                  link_icon {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default NotFoundPage

// export default withUnpublishedPreview(NotFoundPage, {
//   templateMap: {
//     // page: PageTemplate,
//     // homepage: HomeTemplate,
//     // prismicPage: PageTemplate,
//     prismicHomepage: IndexPage,
//   },
// })
